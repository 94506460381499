<template>
  <div class="tags-setting">
    <PageTitle title="標籤設定列表" :hideBtn="true" />
    <div class="flex justify-between mb-[32px]">
      <div>
        <el-button type="primary" class="min-w-[100px]" @click="openDialog('create')">
          新增
        </el-button>
        <el-button plain class="min-w-[100px]" @click="$router.push({name: 'TagsAdvancedSearch'})">
          進階搜尋
        </el-button>
      </div>
      <div>
        <TagTypeFilter
          :options="tagTypeOpions"
          :modelValue.sync="selectedGroups"
          labelName="name"
          groupName="group"
          @update="updateFilterConfig"
        />
      </div>
    </div>
    <div v-for="tagType in displayTagOptions" :key="tagType.value" class="mb-[24px]">
      <el-skeleton
        :loading="getAllTags.isLoading.value"
        animated
      >
        <div slot="template">
          <el-card class="rounded-[20px]">
            <el-skeleton-item
              v-for="i in 5"
              :key="i"
              variant="p"
            />
          </el-card>
        </div>
        <TagGroup
          :key="tagType.value"
          :title="tagType.name"
          :editable="tagType.value === '_custom'"
          :tags="getGroupTags(tagType.value)"
          :sorted="tagType.value !== '_custom'"
          :tooltip-width="tagType.pos ? 200 : 0"
          @update="onChangeTag('update', $event)"
          @delete="onChangeTag('delete', $event)"
        >
          <template #tooltip>
            注意：串接第三方POS數據同步至系統時間為當日凌晨4:00~5:00，後續會每小時更新一次標籤。故POS標籤為非即時數據
          </template>
        </TagGroup>
      </el-skeleton>
    </div>

    <TagForm
      :dialogType="dialogType"
      :show.sync="showDialog"
      :sourceData.sync="formData"
      @submit="onDialogConfirm"
    />
    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteTags(), (deleteDialog = false)"
    />
  </div>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle.vue'
import TagForm from './components/TagForm.vue'
import TagGroup from './components/TagGroup.vue'
import TagTypeFilter from './components/TagTypeFilter.vue'
import { mapGetters } from 'vuex'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import { uiGroupTagTypeList, mapSystem2UI, uiGroupTagFeatureKey } from '@/config/member'
import { computed, onMounted, getCurrentInstance, ref } from 'vue'
import ls from 'local-storage'
import { useAsyncState, useStorage } from '@vueuse/core'
import { KEY_MEMBER_TAG_FILTER } from '@/utils/localstorage'
import { useTagStore } from './components/useTagStore'
import { get, map } from 'lodash'
import { usePermissions } from '@/use/permissions'

export default {
  name: 'TagsSetting',
  components: {
    PageTitle,
    DeleteDialog,
    TagForm,
    TagGroup,
    TagTypeFilter,
  },
  setup () {
    let vm
    const {
      tags: allTags,
      getAllTags,
    } = useTagStore()
    const { checkAction } = usePermissions()
    const req = useAsyncState(getAllTags, {}, { immediate: false })

    const updateAllTags = async () => {
      const [, err] = await req.execute()
      if (err) {
        vm.proxy.$message.error(err)
        return false
      }
      return true
    }

    onMounted(async () => {
      vm = getCurrentInstance()
      updateAllTags()
    })

    // const selectedGroups = useStorage(KEY_MEMBER_TAG_FILTER, [])

    const selectedGroups = ref([])

    const updateFilterConfig = (data) => {
      ls.set('tag-settings-filter', data)
    }

    const checkFilterKeys = () => {
      const filterConfig = ls.get('tag-settings-filter')

      if (!filterConfig) {
        const lastKeys = map(tagTypeOpions.value, 'value')
        const configData = {
          lastKeys,
          config: {},
        }

        tagTypeOpions.value.forEach(item => {
          configData.config[item.value] = { enable: true }
          selectedGroups.value.push(item.value)
        })

        ls.set('tag-settings-filter', configData)
      }

      tagTypeOpions.value.forEach((item) => {
        if (!filterConfig.lastKeys.includes(item.value)) {
          selectedGroups.value.push(item.value)
        } else if (filterConfig.lastKeys.includes(item.value)) {
          if (get(filterConfig.config, `${item.value}.enable`)) selectedGroups.value.push(item.value)
        }
      })
    }

    onMounted(() => {
      checkFilterKeys()
      const validGroups = selectedGroups.value.reduce((groups, groupKey) => {
        if (tagTypeOpions.value.find(opt => opt.value === groupKey)) {
          groups.push(groupKey)
        }
        return groups
      }, [])
      if (validGroups.length === 0) {
        selectedGroups.value = tagTypeOpions.value.map(i => i.value)
      } else {
        selectedGroups.value = validGroups
      }
    })

    const tagTypeOpions = computed(() => {
      const options = uiGroupTagTypeList.map(i => ({ ...i, group: 'system' }))
        .concat([{
          name: '其他',
          value: '_other',
          group: 'system',
        }])

      // return options.filter(item => {
      //   const featureKey = get(uiGroupTagFeatureKey, item.value)
      //   if (!featureKey) return true
      //   return checkAction(featureKey)
      // })
      return options
    })

    const displayTagOptions = computed(() => {
      const res = [{
        name: '自定義標籤',
        value: '_custom',
        group: 'custom',
      }]
      return res.concat(tagTypeOpions.value.filter(tagType => {
        return selectedGroups.value.includes(tagType.value)
      }))
    })

    const displayGroupTags = computed(() => {
      return allTags.value.reduce((groups, tag) => {
        if (!tag.isSystem) {
          groups._custom.push(tag)

          return groups
        }

        const uiGroupName = mapSystem2UI[tag.systemGroup]
        if (!uiGroupName) {
          groups._other.push(tag)
          return groups
        }

        if (groups[uiGroupName]) {
          groups[uiGroupName].push(tag)
        } else {
          groups[uiGroupName] = [tag]
        }

        return groups
      }, { _custom: [], _other: [] })
    })

    const getGroupTags = (groupKey) => {
      const groupTags = displayGroupTags.value[groupKey] || []
      return groupTags.map(i => {
        const { id, name, memberCount: count } = i
        return { id, name, count }
      })
    }

    const onChangeTag = (action, id) => {
      const targeTag = allTags.value.find(i => i.id === id)
      if (action === 'update') {
        vm.proxy.onEditRow(targeTag)
      }
      if (action === 'delete') {
        vm.proxy.deleteDialog = true
        vm.proxy.selectRow = targeTag
      }
    }

    return {
      tagTypeOpions,
      selectedGroups,
      displayGroupTags,
      allTags,
      getGroupTags,
      displayTagOptions,
      onChangeTag,
      getAllTags: req,
      refresh: updateAllTags,
      updateFilterConfig,
    }
  },
  data: () => ({
    showDialog: false,
    deleteDialog: false,
    dialogType: '',
    selectRow: null,

    formData: {
      name: '',
    },
  }),

  computed: {
    ...mapGetters(['shop']),
  },

  methods: {
    onEditRow (row) {
      this.formData = row
      this.openDialog('update')
    },

    async onDialogConfirm (payload) {
      let res
      const type = this.dialogType
      if (type === 'create') res = await this.createTags(payload)
      if (type === 'update') res = await this.updateTags({ ...payload, id: this.formData.id })
      if (!res) return
      this.formData = {}
      this.showDialog = false
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
    //= > 創建標籤
    async createTags (data) {
      const [, err] = await this.$store.dispatch('memberTag/createTag', {
        shopId: this.shop,
        ...data,
      })

      if (err) {
        this.$message.error(err)
        return false
      }
      this.$message.success('新增成功!')
      return true
    },

    //= > 刪除標籤
    async deleteTags () {
      const [, err] = await this.$store.dispatch('memberTag/deleteTag', {
        shopId: this.shop,
        id: this.selectRow.id,
      })
      if (err) {
        this.$message.error(err)
        return false
      }
      this.$message.success('刪除成功!')
      return true
    },

    //= > 更新標籤
    async updateTags (data) {
      const [, err] = await this.$store.dispatch('memberTag/updateTag', {
        shopId: this.shop,
        ...data,
      })
      if (err) {
        this.$message.error({
          message: err || err.message,
        })
        return false
      }
      this.$message.success('更新成功')
      return true
    },
  },
}
</script>
